import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export type QueryParams<Key extends string = string> = Record<
  Key | (string & Record<never, never>),
  string | undefined
>;

type UseQueryStringResult<T extends string> = QueryParams<T> | undefined;

export function useQueryString<
  Key extends string = string,
>(): UseQueryStringResult<Key> {
  const router = useRouter();

  const [params, setParams] = useState<QueryParams<Key>>();

  useEffect(() => {
    if (router.isReady && !params) {
      setParams(
        Object.entries(router.query).reduce<QueryParams>(
          (obj, [key, value]) => {
            const result = Array.isArray(value) ? value[0] : value;
            obj[key] = result;

            return obj;
          },
          {},
        ),
      );

      // remove the query string once we parse it
      window.history.replaceState(
        null,
        window.location.href,
        window.location.pathname,
      );
    }
  }, [router, params]);

  return params;
}
