import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { StoreContext } from 'components/store-provider';
import { Ol } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC, useContext } from 'react';

export const OktaSAMLIntegration: FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep: handleNextStep }) => {
  const {
    appName: [appName],
    connection: [connection],
  } = useContext(StoreContext);

  return (
    <>
      <Text>
        In your Okta Application Dashboard go to "Applications" in the sidebar.
      </Text>
      <Img
        priority
        className="my-8"
        height={940}
        src="/images/5af20543-8b88-4a7d-9fac-cdf5750ceb1f.png"
        width={1408}
      />

      <Text>Click "Create App Integration".</Text>

      <Img
        priority
        className="my-8"
        height={915}
        src="/images/db53e321-105d-47b7-8be1-ec3d93bef3fa.png"
        width={1408}
      />

      <Text>
        Select "Create New App", then select "SAML 2.0" as a Sign on method,
        then click "Next".
      </Text>

      <Img
        className="my-8"
        height={926}
        src="/images/784eac91-0537-4506-b593-4a95072ea846.png"
        width={1407}
      />

      <Text>
        Enter an App name that describes {appName}, then click "Next".
      </Text>

      <Img
        className="my-8"
        height={926}
        src="/images/cd6a6123-e619-49a1-bd56-2a53594b3f44.png"
        width={1407}
      />

      <CopyInput
        label="Copy this Single sign on URL"
        value={connection?.saml_acs_url}
      />

      <CopyInput
        label="Copy this Audience URI (SP Entity ID)"
        value={connection?.saml_entity_id}
      />

      <Text>
        Submit the "Single Sign on URL" and the "Audience URI (SP Entity ID)".
      </Text>

      <Img
        className="my-8"
        height={935}
        src="/images/f596649d-d72f-4275-b617-af83eb0719d1.png"
        width={1407}
      />

      <Confirm
        label="I've configured the Single sign on URL and Audience URI."
        onClick={handleNextStep}
      />
    </>
  );
};

export const OktaAttributeStatements: FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep: handleNextStep }) => {
  const {
    connection: [connection],
  } = useContext(StoreContext);

  return (
    <>
      <Text>Continue scrolling to the Attribute Statements configuration.</Text>

      <Img
        priority
        className="my-8"
        height={603}
        src="/images/e8bd92f9-b21d-409a-883f-b46c032f7458.png"
        width={1457}
      />

      <Text>Fill in the following Attribute Statements and click "Next":</Text>

      <ConnectionAttributeMapping connectionType={connection?.type} />

      <Confirm
        label="I've finished configuring the Attribute Statements
        and clicked “Next”."
        onClick={handleNextStep}
      />
    </>
  );
};

export const OktaApplicationFeedback: FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      Select "I'm an Okta customer adding an internal app" from the options
      menu.
    </Text>

    <Img
      priority
      className="my-8"
      height={853}
      src="/images/8a6cea2c-c387-477c-af5d-b8261d3a7b57.png"
      width={1457}
    />

    <Text>Complete the form with any comments and select “Finish”.</Text>

    <Confirm
      label="I've completed the feedback formed and clicked “Finish”."
      onClick={handleNextStep}
    />
  </>
);

export const OktaAddUsers: FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      In order to test out SSO authentication, you will first need to assign
      your Okta SAML app to Groups.
    </Text>

    <Img
      priority
      className="my-8"
      height={853}
      src="/images/0e09272c-08a4-4305-bf60-7e8e5ff9e6ef.png"
      width={1457}
    />

    <Text>
      Click on the "Assignments" tab of the app and then select "Assign to
      Groups".
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/d1f50dd5-cf6b-4b8c-8fea-c71e40dc3017.png"
      width={1457}
    />

    <Text>
      Find the Group(s) that you would like to assign to the app and click
      "Assign" next to it. Click "Done" when you're finished.
    </Text>

    <Confirm
      label="I've completed the feedback formed and clicked “Finish”."
      onClick={handleNextStep}
    />
  </>
);

export const OktaIdentiyProviderMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
    idpSsoUrlPlaceholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Ol className="my-4">
      <li>Click on the "Sign On" tab of the SAML app you just created.</li>
      <li>
        Click the "Actions" dropdown for the correct certificate and select
        “View IdP Metadata."
      </li>
      <li>A separate tab will open. Copy the link and paste it below.</li>
    </Ol>

    <Img
      className="my-8"
      height={1048}
      src="/images/e98e855e-6733-4f6e-96da-5c993cebd21b.png"
      width={990}
    />
  </MetadataUrlStep>
);
