import { Spinner } from 'components/spinner';
import { pipe } from 'fp-ts/lib/function';
import * as R from 'fp-ts/lib/Record';
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import { setBugsnagSession } from 'utils/bugsnag';
import { graphql } from 'utils/graphql';
import { handleError } from 'utils/handle-errors';
import { getIntentFromCookie, Intent, validateToken } from 'utils/token';
import { useQueryString } from 'utils/use-query-string';
import { withTelemetry } from 'utils/with-telemetry';

const Loader: FC = () => {
  const router = useRouter();
  const queryParams = useQueryString();

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (queryParams) {
        try {
          if (queryParams.token) {
            await validateToken(queryParams.token);
          }

          const response = await withTelemetry(queryParams, () =>
            graphql().InitialPortal(),
          );

          if (response?.data) {
            const { portal_portalSession: session } = response.data;

            setBugsnagSession(session);
          }
        } catch (error) {
          handleError(error);
        }
      }

      if (typeof queryParams !== 'undefined') {
        const intent = getIntentFromCookie();

        // We want to remove the token so the next pages won't try revalidating it
        const queryParamsWithoutToken = pipe(
          queryParams,
          R.filterWithIndex((key) => key !== 'token'),
        );

        if (intent === Intent.DSync) {
          await router.replace('/dsync');
          return;
        } else if (intent === Intent.SSO) {
          await router.replace({
            pathname: '/sso',
            query: queryParamsWithoutToken,
          });
          return;
        } else {
          await router.push('/not_found');
        }
      }
    };

    void load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <div className="h-screen">
      <Spinner isCube />
    </div>
  );
};

export default Loader;
